/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:5fdc9bf0-6953-41cd-8b84-6c7342346f62",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Vj7PgCXVX",
    "aws_user_pools_web_client_id": "4bm1j270u1re19h5jnljf3lhk4",
    "oauth": {
        "domain": "reacttracker3adc7555-3adc7555-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "maddenlog.com-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d29m3g1z359rhb.cloudfront.net"
};


export default awsmobile;
